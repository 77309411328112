<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width:780px">
      <el-form-item label="标题" prop="title" label-width="130px">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="语言" prop="language_code" label-width="130px">
        <el-select v-model="form.language_code">
          <el-option v-for="item in lanlist" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息概要" prop="abstract" label-width="130px">
        <el-input v-model="form.abstract" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="内容" label-width="130px">
        <editor ref="editorone" style="z-index:990;width:650px;" @change="editorchange" v-model="detail"></editor>
      </el-form-item>
      <el-form-item label="预览图片" label-width="130px">
        <!-- <el-input v-model="form.preview_img" autocomplete="off"></el-input> -->
        <el-upload
          class="avatar-uploader"
          :action="previews3url"
          :show-file-list="false"
          :http-request="fileUpload"
          @change="HandelPreviewChange"
          :auto-upload="false"
        >
          <img v-if="previewUrl" :src="previewUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="推送图片" label-width="130px">
        <el-upload
          class="avatar-uploader"
          :action="pushs3url"
          :show-file-list="false"
          :http-request="fileUpload"
          @change="HandelPushChange"
          :auto-upload="false"
        >
          <img v-if="pushUrl" :src="pushUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="邮件附件" prop="attachment" label-width="130px">
        <el-input v-model="form.attachment" autocomplete="off"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="邮件附件" prop="attachment" label-width="130px">
        <el-upload
          class="upload-demo"
          :action="previews3url1"
          :http-request="fileUpload"
          :on-change="handleChange"
          :on-remove="RemoveFile"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">{{attachText}}</el-button>
          <template #tip>
            <div class="el-upload__tip">
              可上传多个文件
            </div>
          </template>
        </el-upload>
      </el-form-item> -->

      <el-form-item label-width="130px">  
        <el-button type="primary" @click="Validate(AddSubmit)">{{edit}}</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import Editor from "../../../components/richtext/index.vue";
import util from '../../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {
    Editor
  },
  data () {
    return {
      templateCode: '',
      imgCode: '',
      edit: '',
      form: {
        code: '',
        message_template_head_code: '',
        title: '',
        language_code: '',
        content: '',
        attachment: '',
        abstract: '',
        preview_img: '',
        push_img: '',
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        language_code: [{ required: true, message: '请选择语言', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        abstract: [{ required: true, message: '请输入消息概要', trigger: 'blur' }],
        preview_img: [{ required: true, message: '请输入预览图片', trigger: 'blur' }],
        push_img: [{ required: true, message: '请输入推送图片', trigger: 'blur' }],
      },
      lanlist: [],
      detail: '',
      richtext: '',
      // 图片
      previews3url: '',
      previewUrl: '',
      pushs3url: '',
      pushUrl: '',
      fileList: [],
      previews3url1: '',
      attachText: '选择附件'
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 邮件附件上传
    RemoveFile (file,fileList) {
      this.fileList = fileList
    },
    handleChange (event) {
      this.GetMd5('attach',event.raw)
    },
    GetMd5(type,file) {
      util.getFileMD5(file, md5 => {
        let params = {
          key_action: 'message',
          key_md5: md5,
          key_type: type=='content'?'email':'email_attach',
          key_suffix: file.name.split('.')[1],
          content_length: file.size,
          content_type: type=='content'?'text/html; charset=utf-8':file.type
        }
        this.GetPreSign(params,file,type)
      })
    },
    // 推送图片
    HandelPreviewChange (event) {
      util.getFileMD5(event.raw, md5 => {
        let params = {
          key_action: 'content_page',
          key_md5: md5,
          key_type: 'display',
          key_suffix: event.raw.name.split('.')[1],
          content_length: event.raw.size,
          content_type: event.raw.type
        }
        this.GetPreSign(params,event.raw,'preview')
      })
    },
    HandelPushChange (event) {
      util.getFileMD5(event.raw, md5 => {
        let params = {
          key_action: 'content_page',
          key_md5: md5,
          key_type: 'thumb',
          key_suffix: event.raw.name.split('.')[1],
          content_length: event.raw.size,
          content_type: event.raw.type
        }
        this.GetPreSign(params,event.raw,'push')
      })
    },
    editorchange (html) {
      // 1.获取内容url
      this.richtext = html
    },
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.lanlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Geteditor () {
      this.api.S3down({key:this.form.content}).then(res => {
        if (res.data.code == 200) {
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
             this.$axios.get('/aws'+ res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]).then(res => {
               if (res.status === 200) {
                 this.$refs.editorone.setHtml(res.data)
              }
            })
          return
        }
        this.$message.error('下载S3失败:' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.TemplateImgDetail({
        code: this.imgCode
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.previewUrl = res.data.data.PreviewImgUrl
          this.pushUrl = res.data.data.PushImgUrl
          this.Geteditor()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    // 文件上传
    fileUpload (file,urls,type) {
      let config = {
        headers: {
          'Content-Type': file.type?file.type:'text/html; charset=utf-8',
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        }
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      let url = '/aws' + urls.split(urlReg.exec(urls)[0])[1]
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          if (type == 'html') {
            this.Addconfirm()
          }
        }
      }).catch( err => {
        console.log(err)
        this.$message.error('上传失败:'+err)
      })
    },
    GetPreSign (params,file,type) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          if (type == 'html') {
            this.form.content = res.data.data.key
            this.fileUpload(file,res.data.data.put_url,'html')
          } else if (type == 'preview') {
            this.previews3url = res.data.data.put_url
            this.form.preview_img = res.data.data.key
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
            this.previewUrl = '/aws' + res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]
            this.fileUpload(file,res.data.data.put_url,'preview')
          } else if (type == 'attach') {
            this.fileList.push({name: file.name,key:res.data.data.key})
            this.previews3url1 = res.data.data.put_url
            this.fileUpload(file,res.data.data.put_url,type)
          } else {
            this.pushs3url = res.data.data.put_url
            this.form.push_img = res.data.data.key
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
            this.pushUrl = '/aws' + res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]
            this.fileUpload(file,res.data.data.put_url,'push')
          }
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    Addconfirm () {
      this.api.TemplateImgUpdate({
        code: this.form.code,
        message_template_head_code: this.templateCode,
        title: this.form.title,
        language_code: this.form.language_code,
        content: this.form.content,
        abstract: this.form.abstract,
        preview_img: this.form.preview_img,
        push_img: this.form.push_img,
      }).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm(this.edit+'成功,是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.edit + '失败' + res.data.msg)
      })
    },
    AddSubmit () {
      // 处理富文本 
      const htmlText = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
    <title>Document</title>
</head>
<body>
    ${this.richtext}
</body>
</html>`
      let blob = new Blob([htmlText],{type : 'text/html'})
      let file = new File([blob],'content.html')
      util.getFileMD5(file, md5 => {
        let params = {
          key_action: 'message',
          key_md5: md5,
          key_type: 'message',
          key_suffix: file.name.split('.')[1],
          content_length: file.size,
          content_type: 'text/html; charset=utf-8'
        }
        this.GetPreSign(params,file,'html')
      })
    },
    goback () {
      this.$router.push('/message/templatemessageadd/' + this.templateCode)
    }
  },
  filters: {},
  mounted () {
    this.templateCode = this.$route.params.code.split('&')[0]
    this.imgCode = this.$route.params.code.split('&')[1]
    if (this.imgCode == 0) {
      this.edit = '创建'
    } else {
      this.edit = '修改'
      this.attachText = "修改附件"
      this.GetInfo()
    }
  },
  created () {
    this.GetLanguage()
  }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
